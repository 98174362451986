import React, { PureComponent } from 'react';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header';
import WCMBC_Logo from '../../assets/wcmbc_logo.svg';
import Card from '../../components/Card';
import Footer from '../../components/Footer';
import './styles.scss';

class Contact extends PureComponent {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div id="contact-page">
                <Navbar selected="contact"/>
                <div id="contact-page-content">
                    <Header />
                    <div id="details">
                        <p> 
                            <strong>Please note!</strong> <br/>
                            We do NOT do any online applications or online payments of any sort.
                            If you would like to become a member of the club, please come to 
                            the dam on the first or third Sunday of the month and ask any of 
                            the members (wearing blue shirts) about becoming a member. 
                            They will be more than happy to point you out to a committee member 
                            that will have membership forms on hand and will be able to explain 
                            the membership process to you. 
                        </p>
                        <Card 
                            name="Club"
                            avatar={ WCMBC_Logo }
                            titles={[
                                "General info",
                                "Photos and Videos",
                            ]}
                            email="info@wcmbc.capetown"
                        />
                        <div className='cool-shops'>
                            <h2>Cool Shops</h2>
                            <strong>Hobby Spot</strong>
                            <div>Boats and boat parts</div>
                            <a href='https://www.facebook.com/p/Hobby-Spot-Edgemead-100083060182610/' target='_blank' rel='noreferrer'>Hobby Spot Edgemead - Facebook Page</a>
                            <br/>
                            <br/>
                            <strong>Speed Models</strong>
                            <div>General - Motors, batteries, esc, servos, etc.</div>
                            <a href='http://www.speed-models.co.za/' target='_blank' rel='noreferrer'>http://www.speed-models.co.za/</a>
                            <br/>
                            <br/>
                            <strong>Cerebus RC</strong>
                            <div>Planes and Jets - For anyone looking to take to the skies</div>
                            <a href='https://www.cerebus.co.za/' target='_blank' rel='noreferrer'>https://www.cerebus.co.za/</a>
                            <br/>
                            <br/>
                            <strong>Flying Robot</strong>
                            <div>Drone Specialists - Great at troubleshooting advanced remotes (RX) and receivers (TX)</div>
                            <a href='https://flyingrobot.co/' target='_blank' rel='noreferrer'>https://flyingrobot.co/</a>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Contact;