import React, { PureComponent } from 'react';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Carousel from '../../components/Carousel';
import Dropdown from '../../components/Dropdown';
import Leaderboard from '../../components/Leaderboard';
import Leaderboard2022Data from '../../data/sailing_leaderboard_2022.json';
import Leaderboard2023Data from '../../data/sailing_leaderboard_2023.json';
import WCMBC_Logo from '../../assets/wcmbc_logo.svg';
import './styles.scss';

class Sailing extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            Leaderboard2024Data: null
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        fetch("data/sailing_leaderboard_2024_Hal.json", {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((json) => this.setState({ Leaderboard2024Data: json }));
    }

    render() {
        return(
            <div id="sailing-page">
                <Navbar selected="sailing"/>
                <div id="sailing-page-content">
                    <Header />
                    <div className="text-container">
                        <img src={WCMBC_Logo} alt="wcmbc logo" className='logo' />
                        <h2>Sailing</h2>
                        <p>
                            'Yachts' include both racing yachts, but also can mean wind-driven vessels - 
                            in the latter case they may well be 'scale models'
                        </p>
                        <p>
                            Many racing yachts are of 'class' designs, and may be certified for compliance 
                            with the class specifications.  An example of these are the 'International One Metre' 
                            (IOM) class.
                        </p>
                        <p>
                            Other designs are also used for model yachting, many of which are used by the Club 
                            members.
                        </p>
                        <p>
                            The club enjoys relaxed competition, provides camaraderie, sportsmanship and helps 
                            build expertise. At present, there is no restriction on the classes sailed in regattas.
                        </p>
                        <p>
                            As a result, extreme competitiveness is not our objective!
                        </p>
                    </div>
                    <Carousel 
                        showStatus={ false }
                        showThumbs={ false }
                        slides={[
                            { 
                                image: require('../../assets/Carousels/Sailing/sailing_0.jpg'), 
                                text: "Course layout"
                            },
                            { 
                                image: require('../../assets/Carousels/Sailing/sailing_1.jpg'), 
                                text: "Sailors taking aim"
                            },
                            { 
                                image: require('../../assets/Carousels/Sailing/sailing_2.jpg'), 
                                text: "Race start!"
                            },
                            { 
                                image: require('../../assets/Carousels/Sailing/sailing_3.jpg'), 
                                text: "First bouy wholeshot challenge"
                            },
                            { 
                                image: require('../../assets/Carousels/Sailing/sailing_4.jpg'), 
                                text: "Coming around the bend"
                            },
                            { 
                                image: require('../../assets/Carousels/Sailing/sailing_5.jpg'), 
                                text: "Waiting for that breeze..."
                            },
                        ]}
                    />
                    <div>
                        *Please refresh the page to ensure you get the latest results
                    </div>
                    {this.state.Leaderboard2024Data ?
                    <Leaderboard 
                        title={ "Leaderboard (2024) (last updated: " + this.state.Leaderboard2024Data.lastUpdated + ")"}
                        data={ this.state.Leaderboard2024Data.leaderboard }
                    />
                    :
                    <div>Loading...</div>
                    }
                    <Dropdown 
                        title="Points System (2024)"
                        content={(
                            <div>
                                <p>
                                    This year we're trying out a new points system.
                                    We'll be using <a href='https://www.halsraceresults.com/'>Hal's Race Results</a> software 
                                    to generate regatta results.
                                </p>
                                <p>
                                    The way it works is as follows:
                                </p>
                                <ul>
                                    <li>A stopwatch is started at the start of the regatta.</li>
                                    <li>Each boats finishing time is recorded.</li>
                                    <li>The times are entered into Hal's software.</li>
                                    <li>Hal's calculates results including handicaps.</li>
                                    <li>The final results are exported from Hal's software into an excel spreadsheet.</li>
                                    {/* <li>The excel sheet for each regatta is available for download from the Regatta Results section above.</li> */}
                                    <li>The total points for the year are tallied up and displayed in the Leaderboard above.</li>
                                </ul>
                            </div>
                        )}
                    />
                    <hr />
                    <Leaderboard 
                        title="Leaderboard (2023)"
                        data={ Leaderboard2023Data }
                        hidden={true}
                    />
                    <hr />
                    <Leaderboard 
                        title="Leaderboard (2022)"
                        data={ Leaderboard2022Data }
                        hidden={true}
                    />
                </div>
                <Footer />
            </div>
        );
    }
}

export default Sailing;